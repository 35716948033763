<template>
  <div class="record-list">
    <div class="search">
      <Input v-model="keyword"
             :placeholder="$t('common_search_keyword')"
             clearable
             search
             :enter-button="true"
             @on-search="changeKeyword"
             style="width: 200px;"
      />
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList"></Table>
    </div>
    <div class="page">
      <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "recordList",
  data(){
    return {
      keyword:'',
      columns: [
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: this.$t('codekey_detail_type'),
          key: 'age',
          render: (h, params) => {
            return h('div', {}, this.typeObj[params.row.type]);
          }
        },
        {
          title: '激活码',
          key: 'address',
          render: (h, params) => {
            return h('span', params.row.cdkeyCode &&  params.row.cdkeyCode.code);
          }
        },
        {
          title: this.$t('codekey_detail_op_user'),
          key: 'address',
          render: (h, params) => {
            return h('span', params.row.creator.nickname);
          }
        },
        {
          title: this.$t('codekey_detail_create_time'),
          key: 'status',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      typeObj:{},
    }
  },
  created(){
    this.dataId = this.$route.params.id;
    this.getList();
  },
  methods:{
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        cdkey_id:this.dataId,
      };
      this.api.codekey.cdkeyRecordList(params).then((res)=>{
        this.dataList = res.list;
        this.typeObj = res.types;
        this.total = Number(res.count);
      })
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.record-list{
  .search{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
