<template>
  <div class="test-info">
    <div class="test-info-breadcrumb">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="layout-content" >
      <div v-if="!loading">
        <Row>
          <i-col span="2">
            <!--            <img v-if="siteDetail.logo" :src="siteDetail.logo">-->
            <div class="head-img">
              <p>{{dataDeatil.site.name && dataDeatil.site.name[0].toUpperCase()}}</p>
              <!--            <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>-->
            </div>
          </i-col>
          <i-col span="22">
            <Row>
              <i-col span="24" class="project-detail-info">
                <div class="info-item" >
                  <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;" v-html="dataDeatil.site.name"></strong>
                </div>
              </i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">激活码ID: {{dataDeatil.id}} </i-col>
              <i-col span="6">{{$t('codekey_detail_active_category')}}:{{dataDeatil.category ? dataDeatil.category.name : '不限'}} - {{dataDeatil.secondCategory ? dataDeatil.secondCategory.name : '不限'}}</i-col>
              <i-col span="6">{{ $t('codekey_detail_active_user') }}: {{dataDeatil.used_count}}</i-col>
              <i-col span="6">剩余个数: {{ dataDeatil.amount - dataDeatil.count}}</i-col>
            </Row>
            <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
              <i-col span="6">{{$t('codekey_detail_active_rate')}}: {{Math.floor(dataDeatil.used_count/dataDeatil.amount * 100) + '%'}}</i-col>
              <i-col span="6">{{ $t('codekey_manage_create_time') }}: {{dataDeatil.created_at}}</i-col>
              <i-col span="6">{{ $t('codekey_manage_update_time') }}: {{dataDeatil.updated_at}}</i-col>
            </Row>
          </i-col>
        </Row>
      </div>
      <Spin fix v-else></Spin>
    </div>
    <div class="cont">
      <div class="cont-top">
        <div class="tab">
          <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="cont-item">
        <component :is="curTab.componentName" :dataDeatil="dataDeatil" :isManage="true"></component>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import ActivationList from './components/detail/activationList'
import RecordList from './components/detail/recordList'

export default {
  name: "test-info",
  data(){
    return{
      dataDeatil:{},
      dataId:'',
      loading:false,
      tabList:[
        {
          name:this.$t('codekey_detail_active_list'),
          id:1,
          componentName:'ActivationList',
          tabName:'list'
        },
        {
          name:this.$t('codekey_detail_record_list'),
          id:2,
          componentName:'RecordList',
          tabName:'record'
        }
      ],
      curTab:{
        name:this.$t('codekey_detail_active_list'),
        id:1,
        componentName:'ActivationList',
        tabName:'list'
      },
    }
  },
  components:{
    ActivationList,
    RecordList
  },
  created(){
    console.log(this.$route.params,'this.$route.params')
    this.dataId = this.$route.params.id;

    if(this.$route.params.tab){
      this.curTab = this.tabList.filter((item)=>{
        return item.tabName == this.$route.params.tab;
      })[0];
    }
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.loading = true;
      this.api.codekey.cdkeyDetail(params).then((res)=>{
        this.loading = false;
        this.dataDeatil = res.info;

        this.dataDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.dataDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        name: 'cdkey-detail',
        params: {
          id: this.$route.params.id,
          tab:this.curTab.tabName
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.test-info{
  .test-info-breadcrumb{
    margin: 20px 0 0 20px;
  }
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 120px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
}
</style>
